<template>
    <div>
        <v-btn small @click="printDialog = true">{{ $t('message.dummyPackingList') }}</v-btn>
        <v-dialog
            persistent
            v-model="printDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="500px"
            min-width="500px"
            max-width="600px"
        >
            <v-card>
                <v-card-title class="success white--text justify-space-between">{{ $t('message.printDummyPackingList') }}</v-card-title>
                <v-card-text class="px-4">
                    <v-form lazy-validation ref="printForm" v-model="validForm">
                        <v-layout row pt-3>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.dummyDate') }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    max-width="290px"
                                    min-width="290px"
                                    ref="ncrPreferredEtdMenu"
                                    transition="scale-transition"
                                    v-model="dummyDateMenu"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :label="dummyPackingListDate ? '' : $t('message.required')"
                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                            :value="computedDummyDateFormatted"
                                            append-icon="event"
                                            clearable
                                            class="force-text-left"
                                            hint="DD/MM/YY format"
                                            hide-details="auto"
                                            background-color="white"
                                            persistent-hint
                                            solo
                                            v-on="on"
                                            @click:clear="dummyPackingListDate = null"
                                            @click:append="dummyDateMenu = true"
                                        />
                                    </template>
                                    <v-date-picker
                                        first-day-of-week="1"
                                        locale-first-day-of-year="4"
                                        show-week
                                        v-model="dummyPackingListDate"
                                        @input="dummyDateMenu = false"
                                    />
                                </v-menu>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.useSupplierLogo') }}</v-flex>
                            <v-flex xs7 lg7 md6 py-1>
                                <v-checkbox
                                    :label="$t('message.yes')"
                                    :true-value="true"
                                    :false-value="false"
                                    class="mt-0"
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="useSupplierLogo"
                                />
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pl-7">
                    <v-btn color="default" small @click="printDialog = false" >{{$t('message.dismiss')}}</v-btn>
                    <v-btn :loading="loading.url" small @click.stop="printDummyPackingList()">
                        <v-icon small>far fa-file-pdf</v-icon>
                        <span class="ml-1">{{ $t('message.pdf') }}</span>
                    </v-btn>
                    <v-spacer/>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {api} from "Api";
import {mapGetters} from "vuex";
import {formatDate} from "Helpers/helpers";

export default {
    name: "DummyPackingList",
    props: ['packingListId'],
    data() {
        return {
            dummyDateMenu: false,
            dummyPackingListDate: null,
            loading: {
                url: false
            },
            printDialog: false,
            useSupplierLogo: false,
            validForm: true
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters([
            'validationRules'
        ]),
        computedDummyDateFormatted() {
            return formatDate(this.dummyPackingListDate)
        }
    },
    methods: {
        getPrintPdfUrl() {
            return new Promise((resolve, reject) => {
                let url = "/print-url/dummy-pl/"
                if(this.useSupplierLogo) url = "/print-url/dummy-pl-from-supplier/"
                api
                    .get(url + this.packingListId, {
                        params: {
                            dummy_date: this.dummyPackingListDate
                        }
                    })
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        printDummyPackingList(){
            if(this.$refs.printForm.validate()) {
                this.loading.url = true;
                this.getPrintPdfUrl()
                    .then(response => {
                        if (response.data.status == 'success') {
                            let tab = window.open(response.data.url, '_blank');
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        } else {
                            this.$toast.error(this.$t('message.errors.pdfError'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                })
                        }
                        this.loading.url = false
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.pdfError'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            })
                        this.loading.url = false
                    })
            }
        }
    },
    watch: {
        dummyPackingListDate(val) {
            const key = 'PL_' + this.packingListId + '_dummyPackingListDate'
            if(val == null) val = ''
            localStorage.setItem(key, val)
        },
        useSupplierLogo(val) {
            const key = 'PL_' + this.packingListId + '_useSupplierLogo'
            localStorage.setItem(key, val ? 1 : 0)
        }
    },
    mounted(){
        const key1 = 'PL_' + this.packingListId + '_dummyPackingListDate'
        if(localStorage.getItem(key1)){
            this.dummyPackingListDate = localStorage.getItem(key1)
        } else {
            this.dummyPackingListDate = new Date().toISOString().substr(0,10)
            localStorage.setItem(key1, this.dummyPackingListDate)
        }
        const key2 = 'PL_' + this.packingListId + '_useSupplierLogo'
        if(localStorage.getItem(key2)){
            this.useSupplierLogo = localStorage.getItem(key2) == 1 ? true : false
        } else {
            this.useSupplierLogo = false
            localStorage.setItem(key2, this.useSupplierLogo ? 1 : 0)
        }
    }
}
</script>

<style scoped>

</style>